<form *ngIf="!isLoading" class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">
        Location Settings
        <ng-container *ngIf="requirePermissions([PermissionCode.location_setting.create]) && (isCreateNew || isEditing)">
          <button nz-button (click)="addSetting()" class="left10">
            <span nz-icon nzType="plus" nzTheme="outline"></span>Add
          </button>
        </ng-container>
      </div>
      <div style="width: 100%"></div>
      <ng-container *ngIf="requirePermissions([PermissionCode.location_setting.update])">
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
      </ng-container>
    </div>

    <div class="group-content">
      <table class="table">
        <thead class="ant-table-thead">
          <tr>
            <th class="ant-table-cell">Warehouse</th>
            <th class="ant-table-cell">Location Type</th>
            <th class="ant-table-cell">Scan Barcode</th>
            <th class="ant-table-cell">POD</th>
            <th class="ant-table-cell">BOL</th>
            <th class="ant-table-cell">Signature</th>
            <th class="ant-table-cell">Geofencing feature</th>
            <th class="ant-table-cell">ID Verification</th>
            <th class="ant-table-cell">Allow pickup more</th>
            <th class="ant-table-cell">Allow pickup less</th>
            <th class="ant-table-cell" *ngIf="requirePermissions([PermissionCode.location_setting.update, PermissionCode.location_setting.delete]) && isEditing">Action</th>
          </tr>
        </thead>
  
        <tbody class="ant-table-tbody">
          <ng-container *ngFor="let groupKey of ['settings'];" [formArrayName]="groupKey">
            <tr class="ant-table-row" *ngFor="let item of getArrayControls(groupKey); let i = index" [formGroupName]="i">
              <td class="ant-table-cell">{{ getValue(i, 'warehouseId') }}</td>
              <td class="ant-table-cell">{{ getValue(i, 'locationType') }}</td>
              <td class="ant-table-cell">{{ getValue(i, 'scanBarcodeRequired') }}</td>
              <td class="ant-table-cell">{{ getValue(i, 'podAtLocationLevel') }}</td>
              <td class="ant-table-cell">{{ getValue(i, 'bolRequired') }}</td>
              <td class="ant-table-cell">{{ getValue(i, 'signatureRequired') }}</td>
              <td class="ant-table-cell">{{ getValue(i, 'useGeoFencing') }}</td>
              <td class="ant-table-cell">{{ getValue(i, 'isIdVerificationRequired') }}</td>
              <td class="ant-table-cell">{{ getValue(i, 'isAllowPickupMoreItems') }}</td>
              <td class="ant-table-cell">{{ getValue(i, 'isAllowPickupLessItems') }}</td>
              <td class="ant-table-cell" *ngIf="requirePermissions([PermissionCode.location_setting.update, PermissionCode.location_setting.delete]) && isEditing">
                <nz-space class="group-button">
                  <ng-container *ngIf="requirePermissions([PermissionCode.location_setting.update]) && (isCreateNew || isEditing)">
                    <span *nzSpaceItem
                      style="cursor: pointer;"
                      nz-icon nzType="edit"
                      nzTheme="outline" (click)="editItemSetting(i)"
                    ></span>
                  </ng-container>
  
                  <ng-container *ngIf="i > 0 && requirePermissions([PermissionCode.location_setting.delete]) && (isCreateNew || isEditing)">
                    <span *nzSpaceItem
                      style="cursor: pointer;"
                      nz-icon nzType="minus-circle"
                      nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                      (click)="removeItemSetting(groupKey, i)"
                    ></span>
                  </ng-container>
                </nz-space>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</form>
